import {ref} from 'vue'

export function useResize() {
    const currW = ref<number>(0)

    const calcVW = () => {
        currW.value = window.innerWidth
        return currW.value
    }

    onMounted(() => {
        calcVW()
        window.addEventListener('onorientationchange', calcVW, true)
        window.addEventListener('resize', calcVW, true)
    })

    onBeforeUnmount(() => {
        window?.removeEventListener('onorientationchange', calcVW, true)
        window?.removeEventListener('resize', calcVW, true)
    })

    return currW
}
